<template>
  <div>
    <stats-card />
    <v-row>
      <v-col cols="12" md="6">
        
        <customers />
      </v-col>
      <v-col cols="12" md="6">
        
        <offices />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatsCard from "./home-components/StatsCard.vue";
import Customers from "./home-components/Customers.vue";
import Offices from "./home-components/Offices.vue";
export default {
  components: {
    StatsCard,
    Customers,
    Offices
  },
  data() {
    return {};
  },

  created() {
    
  },
};
</script>

<style>
</style>