var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.$store.state.loading}},[_c('v-card-title',[_vm._v(" اخر المكاتب ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","no-data-text":"لا يوجد"},on:{"click:row":function (_) {
          _vm.$router.push({
            name: 'office',
            params: {
              id: _.id,
            },
          });
        }},scopedSlots:_vm._u([{key:"item.logoUrl",fn:function(ref){
        var item = ref.item;
return [_c('v-img',{attrs:{"src":_vm.$service.url + item.logoUrl,"contain":"","height":"75","width":"75"}})]}},{key:"item.numberPhone",fn:function(ref){
        var item = ref.item;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(item.numberPhone)+" ")])]}},{key:"item.isActive",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.isActive ? 'success' : 'error',"text":""}},[_vm._v(" "+_vm._s(item.isActive ? "مفعل" : "غير مفعل")+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }