<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text>
              <div class="d-flex align-center justify-space-around">
                <v-icon size="50">mdi-account-multiple</v-icon>
                <div class="d-flex flex-column">
                  <h3 class="font-weight-bold">{{ stats.numberOfUsers }}</h3>
                  <h4>عدد المستخدمين</h4>
                  <small>فعال : {{ stats.numberOfUsersIsActive }}</small>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card color="primary" dark>
            <v-card-text>
              <div class="d-flex align-center justify-space-around">
                <v-icon size="50">mdi-cart</v-icon>
                <div class="d-flex flex-column white--text">
                  <h3 class="font-weight-bold">
                    {{ stats.numberOfOffices }}
                  </h3>
                  <h4>عدد المكاتب العلمية</h4>
                  <small>فعال : {{ stats.numberOfOfficesIsActive }}</small>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text>
              <div class="d-flex align-center justify-space-around">
                <v-icon size="50">mdi-bell</v-icon>
                <div class="d-flex flex-column">
                  <h3 class="font-weight-bold">
                    {{ stats.numberOfOfficesIsActive }}
                  </h3>
                  <h4>عدد المكاتب الفعالة</h4>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text>
              <div class="d-flex align-center justify-space-around">
                <v-icon size="50">mdi-domain</v-icon>
                <div class="d-flex flex-column">
                  <h3 class="font-weight-bold">
                    {{ stats.numberOfCompanies }}
                  </h3>
                  <h4>عدد الشركات</h4>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="6">
      <v-card elevation="0" class="transparent">
        <v-card-title>احصائيات عمل المكاتب</v-card-title>
        <v-card-text>
          <apexchart
            width="550"
            height="200"
            type="line"
            :options="options"
            :series="series"
            ref="chart"
          ></apexchart>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  created() {
    this.$store.commit("setLoading", true);
    this.$http.get("/Dashboards/dashboardCpanel").then((res) => {
      this.stats = res.data.result;
      this.$http.get("/Dashboards/statisticsOffice").then((re) => {
        let res = re.data.result;
        res.forEach((element) => {
          this.options.xaxis.categories.push(element.name);
          this.series
            .filter((el) => el.name == "الاوردر")[0]
            .data.push(element.numberOfOrders);
          this.series
            .filter((el) => el.name == "الشركات")[0]
            .data.push(element.numberOfCompanies);
        });
        this.$refs.chart.updateSeries(this.series);
        this.$store.commit("setLoading", false);
      });
    });
  },
  data: function () {
    return {
      stats: {
        numberOfUsers: 43,
        numberOfUsersIsActive: 43,
        numberOfOffices: 43,
        numberOfOfficesIsActive: 43,
        numberOfCompanies: 8,
      },
      options: {
        colors: ["#3CC2E0", "#2ED67B", "#FFC107"],

        stroke: {
          curve: "smooth",
        },
        chart: {
          zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: false,
          },
          id: "vuechart-example",
          toolbar: {
            show: true,
          },
        },
        xaxis: {
          tickPlacement: 'on',
          categories: [0],
        },
      },
      series: [
        {
          name: "الاوردر",
          data: [0],
        },
        {
          name: "الشركات",
          data: [0],
        },
      ],
    };
  },
};
</script>