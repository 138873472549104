<template>
  <v-card>
    <v-card-title> اخر المالكين المسجلين </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        no-data-text="لا يوجد"
      >
        <template v-slot:item.phoneNumber="{ item }">
          <span dir="ltr">
            {{ item.phoneNumber }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  created() {
    this.$store.commit("setLoading", true);
    this.$http.get("/owners").then((res) => {
      this.$store.commit("setLoading", false);
      this.items = res.data.result;
    });
  },
  data() {
    return {
      headers: [
        {
          text: "الاسم",

          value: "fullName",
        },
        {
          text: "البريد الالكتروني",

          value: "email",
        },
        {
          text: "الهاتف",

          value: "phoneNumber",
        },
        {
          text: "المكتب",

          value: "officeName",
        },
      ],
      items: [],
    };
  },
};
</script>