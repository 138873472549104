<template>
  <v-card :loading="$store.state.loading">
    <v-card-title> اخر المكاتب </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        no-data-text="لا يوجد"
        @click:row="
          (_) => {
            $router.push({
              name: 'office',
              params: {
                id: _.id,
              },
            });
          }
        "
      >
        <template v-slot:item.logoUrl="{ item }">
          <v-img
            :src="$service.url + item.logoUrl"
            contain
            height="75"
            width="75"
          />
        </template>
        <template v-slot:item.numberPhone="{ item }">
          <span dir="ltr">
            {{ item.numberPhone }}
          </span>
        </template>
        <template v-slot:item.isActive="{ item }">
          <v-chip :color="item.isActive ? 'success' : 'error'" text>
            {{ item.isActive ? "مفعل" : "غير مفعل" }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  created() {
    this.$store.commit("setLoading", true);
    this.$http.get("/offices").then((res) => {
      this.$store.commit("setLoading", false);
      this.items = res.data.data;
    });
  },
  data() {
    return {
      headers: [
        { text: "الشعار", value: "logoUrl" },
        {
          text: "الاسم",
          value: "name",
        },
        {
          text: "الحالة",
          value: "isActive",
        },
        {
          text: "رقم الهاتف",
          align: "left",
          value: "numberPhone",
        },
      ],
      items: [],
    };
  },
};
</script>